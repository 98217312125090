import { Component } from 'inferno';
import { getOriginalText } from './util';

class Transcript extends Component {
    render() {
        const { data, authorShow, author, partial } = this.props;
        return (
            <>
                {authorShow && <div class="speaker-separator"></div>}
                <div class="transcript">
                    <div class="photo">{authorShow && author.photoUrl && <img src={author.photoUrl} width="30" alt="profil" />}</div>
                    <div>
                        {authorShow && (
                            <div
                                class="author"
                                style={{
                                    color: author.color,
                                }}
                            >
                                {author.userName}
                            </div>
                        )}
                        <div class="text">
                            {!partial && getOriginalText(data)}
                            {partial && getOriginalText(data).slice(0, (data.lastHumanIndexes || {})[data.speechLang.split('-')[0]])}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Transcript;
