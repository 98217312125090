import firebase from 'firebase/app';
import { Component } from 'inferno';
import './App.css';
import Transcript from './Transcript';
import Toolbar from './Toolbar';
import { getOriginalText } from './util';

const NoSleep = require('nosleep.js');
require('firebase/firestore');

try {
    const noSleep = new NoSleep();

    document.addEventListener(
        'click',
        function enableNoSleep() {
            console.log('enable no-sleep');
            document.removeEventListener('click', enableNoSleep, false);
            noSleep.enable();
        },
        false,
    );
} catch (e) {
    console.error(e);
}

const config = {
    apiKey: 'AIzaSyCtBD2GuK81YMG6gmGm32iVmnNMsFNbc9c',
    databaseURL: 'https://ava-product.firebaseio.com',
    authDomain: 'auth.ava.me',
    projectId: 'ava-product',
    storageBucket: 'ava-product.appspot.com',
    messagingSenderId: '47114113593',
    appId: '1:47114113593:web:15e4a50ec9794553b333e8',
};
firebase.initializeApp(config);
class App extends Component {
    automaticScroll = false;

    constructor(props) {
        super(props);
        const oldTheme = localStorage.getItem('ava-webapp-l-theme');
        let theme = {
            fontSize: 20,
            color: 'dark',
        };
        try {
            if (oldTheme) theme = JSON.parse(oldTheme);
        } catch {
            localStorage.removeItem('ava-webapp-l-theme');
        }
        const oldSafe = localStorage.getItem('ava-follow-safe');
        let safe;
        try {
            if (oldSafe) safe = JSON.parse(oldSafe);
        } catch {
            localStorage.removeItem('ava-follow-safe');
        }
        this.state = {
            link: {},
            idle: false,
            transcripts: {},
            room: {},
            speakers: {},
            theme,
            follow: true,
            safe,
            lastHumanTranscriptIndex: 0,
        };
    }

    async componentWillMount() {
        const pathName = window.location.pathname;
        let doc;
        if (pathName && pathName[1] === '&') {
            doc = await firebase.firestore().collection('avaname-reverse-map').doc(pathName.slice(2));
        } else {
            doc = await firebase
                .firestore()
                .collection('links')
                .doc(`${window.location.href}/`.replace(/\/\/$/, '/').replace(/[ $&/]/g, '-'));
        }
        doc.onSnapshot((d) => {
            const {
                link: { roomId },
            } = this.state;
            const data = d.data();
            this.setState({
                link: { ...data, status: data && data.status ? data.status : 'ongoing' },
            });
            if (this.state.link.roomId !== roomId) {
                this.setState({ transcripts: {} });
                this.reset();
            }
            if (data && data.status && data.status !== 'ongoing') {
                document.title = 'Live Transcript';
            }
        });

        document.onscroll = () => {
            const { follow } = this.state;
            const value = document.body.scrollHeight - (window.scrollY + window.innerHeight);
            if (!this.automaticScroll && value <= 0 && !follow) {
                this.setState({ follow: true });
            } else if (!this.automaticScroll && value > 300 && follow) {
                this.setState({ follow: false });
            }
            this.automaticScroll = false;
        };
    }

    async reset() {
        const {
            link: { roomId },
        } = this.state;
        if (roomId === '-') {
            this.setState({
                transcripts: {},
                room: {},
                speakers: {},
            });
            return;
        }

        await firebase
            .firestore()
            .collection('rooms')
            .doc(roomId)
            .collection('transcripts')
            .onSnapshot((s) => {
                const transcripts = { ...this.state.transcripts };
                const { safe } = this.state;
                const oldTranscriptsLength = Array.from(Object.values(transcripts)).length;
                s.docChanges().forEach((change) => {
                    transcripts[change.doc.data().id] = change.doc.data();
                });
                const transcriptArray = Object.values(transcripts).sort((t1, t2) => t1.timeCreatedMs - t2.timeCreatedMs);
                const lastHumanTranscriptIndex = Math.max(
                    ...transcriptArray.map((t, i) => ((t.lastHumanIndexes || {})[(t.speechLang || '').split('-')[0]] > 0 ? i : 0)),
                );
                if (safe === undefined && oldTranscriptsLength) {
                    this.setState({
                        transcripts,
                        safe: lastHumanTranscriptIndex > 0,
                        lastHumanTranscriptIndex,
                    });
                } else {
                    this.setState({
                        transcripts,
                        lastHumanTranscriptIndex: safe ? lastHumanTranscriptIndex : transcriptArray.length + 1,
                    });
                }
            });
        await firebase
            .firestore()
            .collection('rooms')
            .doc(roomId)
            .collection('speakers')
            .onSnapshot((s) => {
                const speakers = { ...this.state.speakers };
                s.docChanges().forEach((change) => {
                    speakers[change.doc.data().avaId] = change.doc.data();
                });
                this.setState({ speakers });
            });
        await firebase
            .firestore()
            .collection('rooms')
            .doc(roomId)
            .onSnapshot((s) => {
                const room = s.data();
                document.title = room.title;
                this.setState({ room });
            });
    }

    toggleSafe() {
        const { safe } = this.state;
        localStorage.setItem('ava-follow-safe', JSON.stringify(!safe));
        this.setState({ safe: !safe });
    }

    updateTheme(theme) {
        this.setState({ theme });
        localStorage.setItem('ava-webapp-l-theme', JSON.stringify(theme));
    }

    render() {
        const {
            link: { status, placeholder, endedPlaceholder },
            room: { title },
            speakers,
            transcripts,
            theme,
            follow,
            safe,
            lastHumanTranscriptIndex,
        } = this.state;
        if (follow) {
            this.automaticScroll = true;
            setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 10);
        }
        const transcriptArray = Object.values(transcripts).sort((t1, t2) => t1.timeCreatedMs - t2.timeCreatedMs);
        const monoSpeaker = Array.from(Object.keys(speakers)).length === 1;
        let lt = {};
        return (
            <div
                className={`App theme-${theme.color}`}
                // eslint-disable-next-line
                style="display: flex; flex-direction: column"
            >
                {status !== 'ongoing' && (
                    <header className="App-header">
                        {status === undefined && <div>Live Transcript</div>}
                        {status === 'pending' && <div>{placeholder || 'Waiting the session...'}</div>}
                        {status === 'ended' && <div> {endedPlaceholder || placeholder || 'Session ended'}</div>}
                    </header>
                )}
                <header className={`App-body theme-${theme.color} ${monoSpeaker ? 'mono-speaker' : ''}`}>
                    {status === 'ongoing' && (
                        <>
                            <h2>{title}</h2>
                            <Toolbar
                                theme={theme}
                                updateTheme={(newTheme) => this.updateTheme(newTheme)}
                                updateSafe={() => this.toggleSafe()}
                                safe={safe}
                            />
                            {!follow && (
                                <div class="follow-button">
                                    <button
                                        class="no-button"
                                        onClick={() => {
                                            this.setState({ follow: !follow });
                                        }}
                                    >
                                        <i class={`material-icons `}>keyboard_arrow_down</i>
                                    </button>
                                </div>
                            )}
                            <div class={`transcript-container theme-${theme.color}`} style={`font-size: ${theme.fontSize}px;`}>
                                {Array.from(transcriptArray.slice(0, lastHumanTranscriptIndex + 1)).map((t, i) => {
                                    const authorShow = lt.speakerId !== t.speakerId;
                                    if (getOriginalText(t)) {
                                        lt = t;
                                        return (
                                            <Transcript
                                                data={t}
                                                author={speakers[t.speakerId] || {}}
                                                authorShow={authorShow}
                                                partial={lastHumanTranscriptIndex === i}
                                            />
                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                            </div>
                        </>
                    )}
                </header>
                <div className={`footer theme-${theme.color}`} />
            </div>
        );
    }
}

export default App;
