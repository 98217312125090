import { Component } from 'inferno';
import { Idle, NotIdle } from 'idlejs/dist';

const colorThemes = ['dark', 'light'];

class Toolbar extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentWillMount() {
        new Idle()
            .whenNot([
                {
                    events: ['click', 'mousemove', 'mouseenter', 'keydown', 'touchstart'],
                    target: window.document,
                },
            ])
            .whenNotInteractive()
            .within(3, 1000)
            .do(() => {
                this.setState({ idle: true });
            })
            .start();
        new NotIdle()
            .when([
                {
                    events: ['click', 'mousemove', 'mouseenter', 'keydown', 'touchstart'],
                    target: window.document,
                },
            ])
            .within(1, 300)
            .do(() => this.setState({ idle: false }))
            .start();
    }

    updateFontSize(i) {
        const { updateTheme, theme } = this.props;
        updateTheme({
            ...theme,
            fontSize: theme.fontSize + i,
        });
    }

    updateColorTheme(ct) {
        const { updateTheme, theme } = this.props;
        updateTheme({
            ...theme,
            color: ct,
        });
    }

    render() {
        const { idle } = this.state;
        const {
            theme: { color },
        } = this.props;
        const { safe, updateSafe } = this.props;
        return (
            <div class={`toolbar ${idle ? 'hidden' : 'visible'}`}>
                <button class="no-button" onClick={() => this.updateFontSize(-2)}>
                    <i class="material-icons">zoom_out</i>
                </button>
                <button class="no-button" onClick={() => this.updateFontSize(2)}>
                    <i class="material-icons">zoom_in</i>
                </button>
                <button class="no-button" onClick={() => updateSafe()}>
                    {safe && <i class="material-icons">speed</i>}
                    {!safe && <i class="material-icons">security</i>}
                </button>
                {colorThemes
                    .filter((ct) => ct !== color)
                    .map((ct) => (
                        <button class="no-button" onClick={() => this.updateColorTheme(ct)}>
                            <i class={`material-icons theme-${ct}`}>invert_colors</i>
                        </button>
                    ))}
            </div>
        );
    }
}

export default Toolbar;
